import React from "react";

import { Dispatch } from "redux";

import { BEM } from "helpers/BEM.helper";
import FetchModal from "views/common/modals/FetchModal";
import { RouteComponentProps } from "react-router";
import EditPhoneForm from "views/components/forms/EditPhoneForm";
import { FetchConnect } from "helpers/FetchConnect";
import { uiActions } from "slices/ui/ui.actions";
import { IRootStateType } from "types/IRootStateType";
import { currentUserActions } from "slices/currentUser/currentUser.actions";
import { IUserStoreType } from "slices/currentUser/currentUser.reducer";

const classes = new BEM({
  block: { name: "modals" },
  elements: [
    { name: "editPhoneModal" },
    { name: "editPhoneField" },
    { name: "submitButton" },
    { name: "submitButton", extras: ["text-center"] },
  ],
  prefix: { name: "fetch" },
});

export interface EditUserPhoneFormModalProps
  extends IRootStateType,
    RouteComponentProps {
  dispatch: Dispatch;
}

export const EditUserPhoneFormModal: React.FC<EditUserPhoneFormModalProps> = (
  props: EditUserPhoneFormModalProps,
) => {
  const submitUserUpdates = (values: IUserStoreType) => {
    dispatch(currentUserActions.saveMyUser(values));
    dispatch(uiActions.clearModal());
  };
  const { dispatch } = props;
  return (
    <FetchModal
      title="Edit Phone Address"
      className={classes.getElementClassNames("editPhoneModal")}
      id="edit_phone"
    >
      <div className="user-attribute-modal-text-padding">
        Please edit your phone address below.
      </div>
      <EditPhoneForm onSubmit={submitUserUpdates} />
    </FetchModal>
  );
};

export default FetchConnect({
  ConnectedComponent: EditUserPhoneFormModal,
  state: { stores: [] },
});
