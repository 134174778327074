import React from "react";

import { Dispatch } from "redux";

import { BEM } from "helpers/BEM.helper";
import { Button, Form } from "reactstrap";
import { Field, formValueSelector, InjectedFormProps } from "redux-form";
import { FetchFormConnect } from "helpers/FetchFormConnect";
import { FetchInput } from "views/common/forms/fields/FetchInput";
import { toast } from "react-toastify";
import { isValidEmailAddress } from "helpers/inputValidators/text/isValidEmailAddress";
import { IRootStateType } from "types/IRootStateType";
import { FetchFormErrors } from "types/FetchFormErrors";
import { IUserStoreType } from "slices/currentUser/currentUser.reducer";

const classes = new BEM({
  block: { name: "modals" },
  elements: [
    { name: "editEmail" },
    { name: "editEmailField" },
    { name: "emailSubmitButton" },
    { name: "submitButton", extras: ["text-center"] },
  ],
  prefix: { name: "fetch" },
});

interface IAuthDetails {
  initialValues?: {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    userId: string;
  };
}

type EditEmailFormProps = InjectedFormProps<IAuthDetails, IEditEmailFormProps> &
  IEditEmailFormProps;

export interface IEditEmailFormProps {
  currentUser: IUserStoreType;
  dispatch: Dispatch;
  email: string;
}
export interface IEditEmailFormValues {
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
}
type EditEmailFormErrors = FetchFormErrors<IEditEmailFormValues>;

export const EditEmailForm: React.FC<EditEmailFormProps> = (
  props: EditEmailFormProps,
) => {
  const { invalid } = props;
  return (
    <Form
      className={classes.getBlockClassNames()}
      onSubmit={props.handleSubmit}
    >
      <Field
        className={classes.getElementClassNames("editEmail")}
        component={FetchInput}
        label="Email"
        name="email"
        type="text"
      />
      <Button
        type="submit"
        color="info"
        disabled={invalid}
        className={classes.getElementClassNames("emailSubmitButton")}
      >
        Submit
      </Button>
    </Form>
  );
};

const selector = formValueSelector("EditEmailForm");

export default FetchFormConnect({
  FormComponent: EditEmailForm,
  formOptions: {
    form: "EditEmailForm",
    onSubmitFail: () => {
      toast.error("Please fix the error(s) below and try again");
    },
    touchOnChange: true,
    validate: (values: IEditEmailFormValues) => {
      const errors: EditEmailFormErrors = {};
      const requiredFields: (keyof IEditEmailFormValues)[] = ["email"];
      requiredFields.forEach((fieldName: keyof IEditEmailFormValues) => {
        if (!values[fieldName]) {
          errors[fieldName] = "Required";
        }
      });
      if (!isValidEmailAddress(values.email)) {
        errors.email = "Enter a valid email";
      }
      return errors;
    },
  },
  state: {
    mapStateToProps: (rootState: IRootStateType) => {
      const { currentUser } = rootState;
      return {
        initialValues: {
          email: currentUser?.email,
          phone: currentUser?.phone,
          firstName: currentUser?.firstName,
          lastName: currentUser?.lastName,
          userId: currentUser?.userId,
        },
        email: selector(rootState, "email") as string | undefined,
      };
    },
  },
});
