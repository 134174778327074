import React from "react";

import { FetchConnect } from "helpers/FetchConnect";
import ChangePasswordModal from "views/components/modals/ChangePasswordModal";
import ConfirmResetPasswordModal from "views/components/modals/ConfirmResetPasswordModal";
import { LeaveLiabilityWaiverModal } from "views/components/modals/LeaveLiabilityWaiverModal";
import VerifyUserEmailFormModal from "views/components/modals/VerifyUserEmailFormModal";
import VerifyUserPhoneFormModal from "views/components/modals/VerifyUserPhoneFormModal";
import EditUserEmailFormModal from "views/components/modals/EditUserEmailFormModal";
import EditUserPhoneFormModal from "views/components/modals/EditUserPhoneFormModal";
import { UseOurMobileAppModal } from "views/components/modals/UseOurMobileAppModal";
import { IRootStateType } from "types/IRootStateType";

export const FetchModalService: React.FunctionComponent<IRootStateType> = (
  props: IRootStateType,
) => {
  const {
    ui: {
      modals: {
        verify_phone,
        verify_email,
        edit_email,
        edit_phone,
        leave_liability_waiver,
        confirm_reset_password,
        change_password,
        use_our_mobile_app,
      },
    },
  } = props;

  return (
    <div>
      {verify_phone && <VerifyUserPhoneFormModal />}
      {verify_email && <VerifyUserEmailFormModal />}
      {edit_email && <EditUserEmailFormModal />}
      {edit_phone && <EditUserPhoneFormModal />}
      {leave_liability_waiver && <LeaveLiabilityWaiverModal />}
      {confirm_reset_password && <ConfirmResetPasswordModal />}
      {change_password && <ChangePasswordModal />}
      {use_our_mobile_app && <UseOurMobileAppModal />}
    </div>
  );
};

export default FetchConnect({
  ConnectedComponent: FetchModalService,
  state: { stores: ["ui"] },
});
