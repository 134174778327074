import React from "react";

import { Dispatch } from "redux";

import { BEM } from "helpers/BEM.helper";
import { Button, Form } from "reactstrap";
import { Field, formValueSelector, InjectedFormProps } from "redux-form";
import { FetchFormConnect } from "helpers/FetchFormConnect";
import { FetchInput } from "views/common/forms/fields/FetchInput";
import { toast } from "react-toastify";
import { isValidPhoneNumber } from "helpers/inputValidators/text/isValidPhoneNumber";
import { IRootStateType } from "types/IRootStateType";
import { FetchFormErrors } from "types/FetchFormErrors";
import { IUserStoreType } from "slices/currentUser/currentUser.reducer";

const classes = new BEM({
  block: { name: "modals" },
  elements: [
    { name: "editPhone" },
    { name: "editPhoneField" },
    { name: "phoneSubmitButton" },
  ],
  prefix: { name: "fetch" },
});

interface IAuthDetails {
  initialValues?: {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    userId: string;
  };
}

type EditPhoneFormProps = InjectedFormProps<IAuthDetails, IEditPhoneFormProps> &
  IEditPhoneFormProps;

export interface IEditPhoneFormProps {
  currentUser: IUserStoreType;
  dispatch: Dispatch;
  phone: string;
}
export interface IEditPhoneFormValues {
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
}
type EditPhoneFormErrors = FetchFormErrors<IEditPhoneFormValues>;

export const EditPhoneForm: React.FC<EditPhoneFormProps> = (
  props: EditPhoneFormProps,
) => {
  const { invalid } = props;
  return (
    <Form
      className={classes.getBlockClassNames()}
      onSubmit={props.handleSubmit}
    >
      <Field
        className={classes.getElementClassNames("editPhone")}
        component={FetchInput}
        label="Phone"
        name="phone"
        type="text"
      />
      <Button
        type="submit"
        color="info"
        disabled={invalid}
        className={classes.getElementClassNames("phoneSubmitButton")}
      >
        Submit
      </Button>
    </Form>
  );
};

const selector = formValueSelector("EditPhoneForm");

export default FetchFormConnect({
  FormComponent: EditPhoneForm,
  formOptions: {
    form: "EditPhoneForm",
    onSubmitFail: () => {
      toast.error("Please fix the error(s) below and try again");
    },
    touchOnChange: true,
    validate: (values: IEditPhoneFormValues) => {
      const errors: EditPhoneFormErrors = {};
      const requiredFields: (keyof IEditPhoneFormValues)[] = ["phone"];
      requiredFields.forEach((fieldName: keyof IEditPhoneFormValues) => {
        if (!values[fieldName]) {
          errors[fieldName] = "Required";
        }
      });
      if (!isValidPhoneNumber(values.phone)) {
        errors.phone = "Enter a valid phone";
      }
      return errors;
    },
  },
  state: {
    mapStateToProps: (rootState: IRootStateType) => {
      const { currentUser } = rootState;
      return {
        initialValues: {
          email: currentUser?.email,
          phone: currentUser?.phone,
          firstName: currentUser?.firstName,
          lastName: currentUser?.lastName,
          userId: currentUser?.userId,
        },
        phone: selector(rootState, "phone") as string | undefined,
      };
    },
  },
});
